import React from 'react';
import './NavBar.css';
import logo from './fox-logo.jpg';
import { Link } from 'react-router-dom';
import { IoClose, IoMenu } from "react-icons/io5";
import { FormattedMessage } from 'react-intl';

const NavBar = () => {
    return (
        <header>
            <nav className="navbar">
                <img src={logo} className="navbar-logo" alt="logo" />
                <div className="nav-name">
                    <span className="title">FOX</span>
                    <span className="name">Software Solutions</span>
                </div>
                <ul className="navbar-links">
                    <li>
                        <Link to="/">
                            <FormattedMessage id="app.home" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/services">
                            <FormattedMessage id="app.services" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            <FormattedMessage id="app.contact" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/about">
                            <FormattedMessage id="app.about" />
                        </Link>
                    </li>
                </ul>
                <div className="nav-close">
                    <IoClose />
                </div>
                <div className="nav-toggle">
                    <IoMenu />
                </div>
            </nav>
        </header >
    );
};

export default NavBar;