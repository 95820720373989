const Footer = () => {
    return (
        <footer className="footer">
            <p><b><a href="mailto:&#x63;&#x6F;&#x6E;&#x74;&#x61;&#x63;&#x74;&#x40;&#x66;&#x6F;&#x78;&#x2D;&#x73;&#x6F;&#x66;&#x74;&#x77;&#x61;&#x72;&#x65;&#x2D;&#x73;&#x6F;&#x6C;&#x75;&#x74;&#x69;&#x6F;&#x6E;&#x73;&#x2E;&#x65;&#x75;">contact [at] fox-software-solutions [dot] eu</a></b></p >
            <div className="logos">
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/tafox" className="fa fa-linkedin"></a>
                <a target="_blank" rel="noreferrer" href="https://github.com/tafox" className="fa fa-github"></a>
            </div>
            <span className="copyright">© 2024 Fox Software Solutions</span>
        </footer >
    );
};

export default Footer;