import React from 'react';
import { FormattedMessage } from 'react-intl';

const About: React.FC = () => {
    return (
        <div className="about">
            <h1>
                <FormattedMessage id="app.about" />
            </h1>
            <p>
                <FormattedMessage id="app.about.learn" />
                <b>Fox</b> <i>Software Solutions</i>!
            </p>
            <p>
                <FormattedMessage id="app.about.exp" />
            </p>
            <p>
                <FormattedMessage id="app.about.bad" />
            </p>
            <p>
                <FormattedMessage id="app.about.worry" />
            </p>
            <p>
                <b><i>
                    <FormattedMessage id="app.great" />
                </i></b>
                <FormattedMessage id="app.about.great1" />
            </p>
            <p>
                <FormattedMessage id="app.about.mission" />
                <b><i>
                    <FormattedMessage id="app.about.great" />
                </i></b>
                <FormattedMessage id="app.about.mission2" />
                <i><FormattedMessage id="app.about.mission3" /></i>!
            </p>
        </div>
    );
};

export default About;