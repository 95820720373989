
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Contact: React.FC = () => {
    return (
        <div className="contact">
            <h1>
                <FormattedMessage id="app.contact" />
            </h1>
            <p>
                <FormattedMessage id="app.contact.msg" />
            </p>
            <p><b><a href="mailto:&#x63;&#x6F;&#x6E;&#x74;&#x61;&#x63;&#x74;&#x40;&#x66;&#x6F;&#x78;&#x2D;&#x73;&#x6F;&#x66;&#x74;&#x77;&#x61;&#x72;&#x65;&#x2D;&#x73;&#x6F;&#x6C;&#x75;&#x74;&#x69;&#x6F;&#x6E;&#x73;&#x2E;&#x65;&#x75;">contact [at] fox-software-solutions [dot] eu</a></b></p>
            <p>
                <FormattedMessage id="app.contact.wish" />
            </p>

            {/* <form>
                <label>
                    Name:
                    <input type="text" name="name" />
                </label>
                <br />
                <label>
                    Email:
                    <input type="email" name="email" />
                </label>
            </form> */}
        </div >
    );
}

export default Contact;